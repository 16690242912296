
<template>
        <div id="main" v-html="html">
        </div>
</template>

<script>
//import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse, AxiosStatic } from 'axios';
import axios from 'axios';

export default {
  name: 'Outillage',

  data() {
      return {
          html: "Prochainement une zone de partage d'outils"
      }
  },

  created : function() {
      var baseurl = "/api/"
      //  var baseurl = "/data"
      axios.get(baseurl+'?type='+this.rep+'&num='+this.num).then(response =>{
        this.html = response.data;      
     })
  }
}

</script> 
